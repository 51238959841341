<template>
  <div class="page-wrp">
    <!-- 查询表单 -->
    <div class="page-wrp-query" v-if="!$_.isEmpty(queryFormState)">
      <a-form-model ref="queryForm" layout="inline" :model="queryFormState">
        <!-- <a-form-model-item
          v-for="item in queryFormFields"
          :key="item.name"
          :label="item.label"
        >
          <component
            :is="item.InputComponent"
            :placeholder="item.inputProp.placeholder"
            v-model="queryFormState[item.name]"
          >
            <component :is="item.inputProp.children"></component>
          </component>
        </a-form-model-item> -->
        <!-- 查询表单 -->
        <slot name="query"></slot>
        <a-form-model-item>
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </div>
    <!-- 操作按钮 -->
    <div class="page-wrp-action">
      <a-space>
        <slot name="action"></slot>
        <a-button icon="reload" @click="query">刷新</a-button>
      </a-space>
    </div>
    <!-- 表格数据 -->
    <div class="page-wrp-table">
      <PagingQueryTable
        :size="size"
        :bordered="bordered"
        :loading="loading"
        :rowKey="rowKey"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="paginationState && pagination"
      />
    </div>
  </div>
</template>

<script>
import PagingQueryTable from "../PagingQueryTable";
import { DitiPagingInitState } from "@/config/enum";
import request from "@/utils/request";
export default {
  name: "PagingQueryPage",
  components: { PagingQueryTable },
  props: {
    // 查询表单配置
    queryFormState: { type: Object }, // 表格查询字段状态
    // 操作按钮设置
    // 表格数据配置
    size: { type: String, default: "default" }, // 表格大小 default | middle | small
    bordered: { type: Boolean, default: true }, // 表格是否有边框
    rowKey: { type: [String, Function], default: "id" }, // 表格key 字符串或者函数
    columns: { type: Array, default: () => [] }, // 表格列配置
    paginationState: { type: [Boolean, Object], default: true }, // 分页配置
    dataUrl: { type: String }, // 表格数据请求地址
    requestOptions: { type: Object, default: () => {} }, // 表格请求配置
    requestMethod: { type: String, default: "POST" }, // 数据请求方式
    requestInterceptor: { type: Function }, // 请求前拦截
    responseFilter: { type: Function }, // 请求后响应拦截
    requestError: { type: Function }, // 请求失败处理   (resData, response, error) => (Object<resData> | undefined | null)
    requestSuccessful: { type: Function }, // 请求成功回调 (resData, response) => ()
  },
  data() {
    return {
      loading: false, // 表格加载状态
      dataSource: [], // 表格数据
      pagination: {
        onChange: (page) => {
          this.numOfPage = page + "";
          this.query({ numOfPage: page + "" });
        },
        showTotal: (total) => {
          return "总条数" + total;
        },
      },
      numOfPage: "1",
    };
  },
  created() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.query();
      }, 500);
    });
  },
  watch: {
    queryFormState: function () {
      console.log("watch", this.queryFormState);
    },
  },
  methods: {
    // 查询
    query(paginationObj) {
      this.loading = true;
      if (
        this.dataUrl === null ||
        this.dataUrl === undefined ||
        this.dataUrl === ""
      ) {
        this.loading = false;
        return;
      }
      const queryParam = {
        ...DitiPagingInitState.queryParam,
        ...this.queryFormState,
        ...paginationObj,
        numOfPage: this.numOfPage,
      };
      const fetchOptions = {
        url: this.dataUrl,
        method: this.requestMethod,
        data: { ...queryParam, ...this.requestOptions },
      };
      // 请求前拦截
      if (this.requestInterceptor instanceof Function) {
        const tmp = this.requestInterceptor();
        if (tmp === false) {
          this.loading = false;
          return;
        }
        if (tmp && tmp.url) {
          fetchOptions.url = tmp.url;
        }
        if (tmp && tmp.options) {
          fetchOptions.options = tmp.options;
        }
      }
      request(fetchOptions).then((res) => {
        const {
          code,
          data: { resList, pageInfo },
        } = res.data;
        if (code === "200") {
          if (this.responseFilter instanceof Function) {
            this.dataSource = this.responseFilter(resList);
          } else {
            this.dataSource = resList;
          }
          if (!this.$_.isEmpty(pageInfo)) {
            this.pagination.current = parseInt(pageInfo.numOfPage);
            this.pagination.pageSize = parseInt(pageInfo.rowsInPage);
            this.pagination.total = parseInt(pageInfo.totalNum);
          } else {
            this.pagination.total = parseInt(resList.length);
          }
          this.loading = false;
        }
      });
    },
    // 重置查询表单
    // 先清空查询表单，后根据父组件的reset函数完成初始化，保证默认值
    reset() {
      const obj = this.queryFormState;
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          obj[key] = null;
        }
      }
      const { reset } = this.$parent;
      if (reset instanceof Function) {
        reset();
      }
      this.$refs["queryForm"].resetFields();
    },
  },
};
</script>
