import request from "@/utils/request";

function permissionFilter(data) {
  return request({
    url: "/show/permission/filter.diti",
    method: "post",
    data,
  });
}

function permissionAdd(data) {
  return request({
    url: "/show/permission/add.diti",
    method: "post",
    data,
  });
}

function permissionUpdate(data) {
  return request({
    url: "/show/permission/update.diti",
    method: "post",
    data,
  });
}

function permissionDel(data) {
  return request({
    url: "/show/permission/del.diti",
    method: "post",
    data,
  });
}

export { permissionFilter, permissionAdd, permissionUpdate, permissionDel };
